.icons {
    list-style-type: none;
    overflow: hidden;
}

.icons li:first-child {
    margin-left: 0;
}

.icons li {
    float: left;
    margin-left: 0.5em;
}
