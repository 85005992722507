.column {
    float: left;
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
    .column {
        width: 100%;
        padding: 0px;
        height: 100% !important;
        float: unset;
        text-align: left !important;
    }
}
