.row {
  width: 70%;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .row {
    width: 95%;
    padding-left: 0 24px;
    max-width: unset;
  }
}

.row:after {
  content: "";
  display: table;
  clear: both;
}